/**
 * @param {Object|*} obj
 * @returns {Boolean}
 */
export const isObject = obj => (obj && typeof obj === "object" && !Array.isArray(obj))

/**
 * @param {Object} obj
 * @returns {Boolean}
 */
export const isEmpty = obj => {
  if (isObject(obj)) {
    return Object.keys(obj).length === 0
  }

  return true
}

/**
 * @param {Object|*} obj
 * @returns {number}
 */
export const length = obj => (obj && obj.constructor === Object) ? Object.keys(obj).length : 0

/**
 * @param {Object} obj
 * @param {string|number} key
 * @returns {Boolean}
 */
export const keyExists = (obj, key) => obj.hasOwnProperty(key)

/**
 * @param {Object} obj
 * @param {Array<string|number>} props
 */
export const removeProps = (obj, props) => {
  if (props.length > 0) {
    for (const prop of props) {
      removeProperty(obj, prop)
    }
  }
}

/**
 * @param {Object} obj
 * @param {string|number} prop
 */
export const removeProperty = (obj, prop) => {
  if (isObject(obj) && keyExists(obj, prop)) {
    delete obj[prop]
  }
}

/**
 * @param {Object} defaults
 * @param {Object} options
 * @returns {Object}
 */
export const mergeDeep = (defaults, options) => {
  if (isObject(defaults) && isObject(options)) {
    for (const key in options) {
      if (isObject(options[key])) {
        if (!defaults[key]) Object.assign(defaults, { [key]: {} });
        mergeDeep(defaults[key], options[key]);
      } else {
        Object.assign(defaults, { [key]: options[key] });
      }
    }
  }
  return defaults;
}

/**
 * @param {string} string
 * @returns {[*]|[null,Object]|[undefined]}
 */
export const safeJsonParse = (string) => {
  try {
    return [null, JSON.parse(string)]
  } catch (err) {
    return [err]
  }
}

/**
 * @param {Object|string} opts
 * @returns {Object|any}
 */
export const formatDataOptions = (opts) => {
  switch (typeof opts) {
    case "object":
      return opts
    case "string":
      try {
        return JSON.parse(opts.replace(/'/g,'"').replace(";",""))
      } catch(e) {
        return {}
      }
    default:
      return {}
  }
}

/**
 * @param {HTMLFormElement} form
 * @returns {{[p: string]: string | File | Array} | Object}
 */
export const formToObject = form => Object.fromEntries(new FormData(form))

/**
 * @param {Object} data
 * @returns {FormData}
 */
export const objectToFormData = (data) => {
  const form = new FormData()

  for (let d in data) {
    form.append(d, data[d])
  }

  return form
}

/**
 * Convert objet to Url Params
 *
 * @param {Object} data
 * @return {URLSearchParams}
 */
export const objectToUrlParams = (data) => {
  const form = new URLSearchParams()

  for (let d in data) {
    form.append(d, data[d])
  }

  return form
}
