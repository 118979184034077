/**
 * @param {HTMLElement} element
 * @param {string} search
 * @returns {HTMLElement[]|Element}
 */
export const siblings = (element, search = "") => {
  const parent = element.parentElement

  if (search !== "" && parent.querySelector(search) !== null) {
    return parent.querySelector(search)
  }

  let siblings = []
  let sibling = parent.firstChild;

  for (; sibling; sibling = sibling.nextElementSibling) {
    if (sibling.nodeType === 1 && sibling !== element) {
      siblings.push(sibling)
    }
  }

  return siblings
}

/**
 * @param {Element} element
 * @param {string|null} matches
 * @returns {Element|null}
 */
export const nextSibling = (element, matches = null) => {
  let placeholder = element.nextElementSibling

  if (matches === null) {
    return placeholder
  }

  let sibling = null

  while (placeholder) {
    if (placeholder.matches(matches)) {
      sibling = placeholder
      break
    }

    placeholder = placeholder.nextElementSibling
  }

  return sibling
}

/**
 * @param {Element} element
 * @param {string|null} matches
 * @returns {Element|null}
 */
export const previousSibling = (element, matches = null) => {
  let placeholder = element.previousElementSibling

  if (matches === null) {
    return placeholder
  }

  let sibling = null

  while (placeholder) {
    if (placeholder.matches(matches)) {
      sibling = placeholder
      break
    }

    placeholder = placeholder.previousElementSibling
  }

  return sibling
}

/**
 * @param {Array<Element|EventTarget>} elements
 */
export const enableElements = (elements) => {
  if (elements.length > 0) {
    elements.forEach(element => (element.disabled = false))
  }
}

/**
 * @param {Array<Element|EventTarget>} elements
 */
export const disableElements = (elements) => {
  if (elements.length > 0) {
    elements.forEach(element => (element.disabled = true))
  }
}