import { checkValue } from "Utils/Validation"
import { isObject, safeJsonParse } from "Utils/Object"

/**
 * @param {Function} callback
 * @param {number} delay
 * @returns {(function(): void)|*}
 */
export const debounce = (callback, delay = 300) => {
  let timer

  return function () {
    const args = arguments
    const context = this

    clearTimeout(timer)

    timer = setTimeout(() => {
      callback.apply(context, args)
    }, delay)
  }
}

/**
 * @param {Function} callback
 * @param {number} delay
 * @returns {(function(...[*]): void)|*}
 */
export const throttle = (callback, delay) => {
  let wait = false
  let storedArgs = null

  function checkStoredArgs () {
    if (storedArgs == null) {
      wait = false
    } else {
      callback(...storedArgs)
      storedArgs = null
      setTimeout(checkStoredArgs, delay)
    }
  }

  return (...args) => {
    if (wait) {
      storedArgs = args
      return
    }

    callback(...args)
    wait = true
    setTimeout(checkStoredArgs, delay)
  }
}

/**
 * @param {string} key
 * @param {string|object|number} value
 */
export const setLocalStorage = (key, value) => {
  if (checkValue(key) && checkValue(value)) {
    value = isObject(value) ? JSON.stringify(value) : value;
    localStorage.setItem(key, value)
  }
}

/**
 * @param {string} key
 * @returns {string|Object|null}
 */
export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)

  if (value) {
    const [err, result] = safeJsonParse(value)
    return err ? value : result
  }

  return null
}

/**
 * @param {string} key
 */
export const removeLocalStorage = key => localStorage.removeItem(key)

/**
 * @param {HTMLElement|Node} elToWatch
 * @param {Function} callback
 * @param {HTMLElement} parent
 */
export const observeAddedElement = (elToWatch, callback, parent = document.querySelector("body")) => {
  const observer = new MutationObserver(() => {
    if (elToWatch) {
      callback(observer)
    }
  })

  // start observing the parent - defaults to document body
  observer.observe(parent, { childList: true, subtree: true })
}

/**
 * @param {Element|Window} element
 * @param {string} type
 */
export const triggerEvent = (element, type) => {
  const event = new Event(type)
  element.dispatchEvent(event)
}

/**
 *
 * @param {Number} pos
 * @param {Number} time
 */
export const scrollToSmoothly = (pos, time = 500) => {
  const currentPos = window.scrollY;
  let start = null;

  pos = +pos
  time = +time

  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start
    const progress = currentTime - start

    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos)
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time))
    }
    if (progress < time) {
      window.requestAnimationFrame(step)
    } else {
      window.scrollTo(0, pos)
    }
  })
}
