import "bootstrap/js/dist/alert"
import "bootstrap/js/dist/dropdown"
import { setErrorPlacement } from "Utils/Form"
import { nextSibling } from "Utils/Element"
import "jquery-validation"
import "jquery-validation/dist/localization/messages_ar.min"

document.addEventListener("DOMContentLoaded", async function () {
  if (document.querySelector("#app") !== null) {
    const {Frontend} = await import("Themes/Frontend")
    new Frontend()
  }

  if (document.querySelector("#backend-app") !== null) {
    const {Backend} = await import("Themes/Backend")
    new Backend()
  }

  if (($.isFunction($.fn.validate))) {
    $.validator.setDefaults({
      lang: "ar",
      errorElement: "div",
      errorClass: "invalid-feedback",
      highlight: element => element.classList.add("is-invalid"),
      unhighlight: function (element) {
        element.classList.remove("is-invalid")

        if (element.classList.contains("select2-hidden-accessible")) {
          const span = nextSibling(element, "span")

          if (span !== null) {
            span.classList.remove("select2-has-error")
          }
        }
      },
      errorPlacement: setErrorPlacement
    })
  }
})
