/**
 * @param {string} value
 * @returns {number}
 */
export const formatToNumber = value => parseInt(value.replace(/\s/g, ""), 10)

/**
 * @param number
 * @param {number} decimals
 * @param {string} decPoint
 * @param {string} thousandsSep
 * @returns {string}
 */
export const formatNumber = (number, decimals = 0, decPoint = ".", thousandsSep = " ") => {
  let numeric = (number + "").replace(/[^0-9+\-Ee.]/g, "")
  const n = !isFinite(+numeric) ? 0 : +numeric

  const toFixedFix = (n, prec) => {
    const k = Math.pow(10, prec)
    return "" + (Math.round(n * k) / k).toFixed(prec)
  }

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0
  let s = (decimals ? toFixedFix(n, decimals) : "" + Math.round(n)).split(".")
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep)
  }
  if ((s[1] || "").length < decimals) {
    s[1] = s[1] || ""
    s[1] += new Array(decimals - s[1].length + 1).join("0")
  }

  return s.join(decPoint)
}

/**
 * Format Bytes to ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
 *
 * @param {Number} bytes
 * @param {Number} decimals
 * @param {string} format
 * @returns {string}
 */
export const formatBytes = (bytes, decimals = 2, format = "b") => {
  if (bytes === 0) {
    return "0 Bytes"
  }

  const k = 1024
  const number = format === "o" ? bytes / 8 : bytes
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(number) / Math.log(k))

  return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const formatToPercent = (num, min, max) => ((num - min) / (max - min) * 100)
