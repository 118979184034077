export const checkValue = value => !!value

export const alnum_dash = value => {
  if (value !== "") {
    const pattern = new RegExp(/^[a-zA-Z0-9-]+$/)

    return pattern.test(value)
  }

  return true
}

export const alnum_underscore = value => {
  if (value !== "") {
    const pattern = new RegExp(/^[a-zA-Z0-9_]+$/)

    return pattern.test(value)
  }

  return true
}

export const exact_length = (value, b, expected) => {
  if (value !== "") {
    return value.length === expected
  }

  return true
}

export const valid_url = value => {
  return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
}

export const valid_urls = value => {
  if (value !== "") {
    const urls = value.split("\n")

    for (let i = 0; i < urls.length; i++) {
      if (!valid_url(urls[i])) {
        return false
      }
    }
  }

  return true
}
