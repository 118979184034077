import { trimByChar } from "./String"
import { keyExists } from "Utils/Object"

/**
 * @return {Location}
 */
export const getPageLocation = () => window.location

/**
 * @param {string} segment
 * @returns {string}
 */
export const getBaseUrl = (segment = "") => getPageLocation().protocol + "//" + getPageLocation().hostname + "/" + segment

/**
 * @param {string} uri
 * @returns {string}
 */
export const getPrefixUrl = (uri = "") => {
  let prefix = document.getElementById("prefix")
  prefix = prefix !== null ? prefix.value : ""
  return getBaseUrl(prefix + uri)
}

/**
 * @param {string} segment
 * @param {string} url
 * @returns {Boolean}
 */
export const checkSegmentInUrl = (segment, url = "") => {
  url = url !== "" ? url : getBaseUrl(window.location.pathname + window.location.search)
  const segments = url.split("/")

  return segments.indexOf(segment) !== -1
}

/**
 * @return {string[]}
 */
export const getPageUriSegments = () => trimByChar(getPageLocation().pathname, "/").split("/")

const iconVer = "a"
/**
 * @param {string} name
 * @param {Object} options
 * @returns {string}
 */
export const icon = (name, options = {}) => {
  const className = keyExists(options, "className") ? " " + options.className : "";
  const attr = keyExists(options, "attr") ? " " + options.attr : "";

  return `<svg class="icon icon-${name}${className}"${attr}><use xlink:href="/sprite.svg?${iconVer}#${name}"></use></svg>`
}

/**
 * @param {string} name
 * @param {Object} options
 * @returns {string}
 */
export const icon_status = (name, options = {}) => {
  const className = keyExists(options, "className") ? " " + options.className : "";
  const attr = keyExists(options, "attr") ? " " + options.attr : "";

  return `<svg class="icon icon-${name}${className}"${attr}><use xlink:href="/status.svg?${iconVer}#${name}"></use></svg>`
}
