/**
 * @param {string} string
 * @param {string} character
 * @return {string}
 */
export const trimByChar = (string, character) => {
  const arr = Array.from(string);
  const first = arr.findIndex(char => char !== character);
  const last = arr.reverse().findIndex(char => char !== character);
  return (first === -1 && last === -1) ? "" : string.substring(first, string.length - last);
}

/**
 * @returns {string}
 */
export const getDeviceType = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile"
  }

  return "desktop"
}

/**
 * @param {string} html
 * @returns {string}
 */
export const stripTags = html => {
  const div = document.createElement("div")
  div.innerHTML = html
  return div.textContent || div.innerText
}
