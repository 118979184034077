import { mergeDeep } from "Utils/Object"

/**
 * @param {Object} options
 * @returns {Promise<*>}
 */
export const sweetAlertConfirm = (options = {}) => {
  const defaults = {
    title: "التأكيد",
    content: "هل أنت متأكد من أنك تريد تنفيذ هذا الإجراء؟",
    icon: "warning", // "warning", "error", "success", "info"
    buttons: {
      cancel: {
        text: "إلغاء",
        visible: true,
        value: "cancel",
      },
      confirm: {
        text: "نعم، متأكد",
        value: "confirm",
      },
    },
    closeOnClickOutside: false,
    closeOnEsc: false,
    dangerMode: true,
  }
  const params = mergeDeep(defaults, options)
  params.content = getMessage(params.content)

  return import("sweetalert").then(({default: swal}) => {
    return swal(params)
  })
}

/**
 * @param {Object} options
 * @param {boolean} error
 * @returns {Promise<void>}
 */
export const snackbarNotify = async (options = {}, error = false) => {
  const { default: Snackbar } = await import("node-snackbar")
  const defaults = {
    text: "تمت العملية بنجاح.",
    actionText: "إغلاق",
    backgroundColor: "#121519",
    pos: "bottom-left",
    customClass: null,
    width: "auto",
    showAction: true,
    duration: 5_000,
  }

  if (error === true) {
    defaults.actionTextColor = "#dc3545"
  }

  Snackbar.show(mergeDeep(defaults, options))
}

/**
 * @param {string} text
 * @param {string} className
 * @returns {HTMLDivElement|string}
 */
const getMessage = (text, className = "text-dark") => {
  const message = document.createElement("div")
  message.innerHTML = text
  message.className = className

  return message
}
