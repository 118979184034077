import { formatBytes, formatNumber } from "Utils/Number"
import { getBaseUrl, icon } from "Utils/Uri"
import { nextSibling, siblings, disableElements, enableElements } from "Utils/Element"
import { mergeDeep, formToObject, keyExists, isEmpty } from "Utils/Object"
import { snackbarNotify } from "Utils/Alert"
import { triggerEvent } from "Utils/Functions"

/**
 * @param {HTMLFormElement} form
 */
export const clearFormErrors = (form) => {
  const errors = form.querySelectorAll(".invalid-feedback")
  const elements = form.querySelectorAll(".is-invalid")

  if (errors.length > 0) {
    for (const error of errors) {
      error.remove()
    }
  }

  if (elements.length > 0) {
    for (const element of elements) {
      element.classList.remove("is-invalid")
    }
  }
}

/**
 * @param {HTMLElement} element
 * @param {string} message
 */
export const displayElementError = (element, message) => {
  const group = element.closest(".form-group")
  const error = document.createElement("div")
  element.classList.add("is-invalid")
  error.classList.add("invalid-feedback")
  error.innerText = message
  group.appendChild(error)
}

/**
 * @param {HTMLElement} container
 * @param {Object} errors
 */
export const displayModalErrors = (container, errors) => {
  if (!isEmpty(errors)) {
    Object.entries(errors).forEach(entry => {
      const element = container.querySelector("#" + entry[0])

      if (element !== null) {
        const error = document.createElement("div")
        error.classList.add("invalid-feedback")
        error.textContent = entry[1].toString()
        element.classList.add("is-invalid")
        errorPlacement(element, error)
      }
    })
  }
}

/**
 * @param {HTMLElement} element
 * @param {string} message
 */
export const showInputGroupError = (element, message) => {
  const group = element.closest(".input-group")
  const error = document.createElement("div")
  element.classList.add("is-invalid")
  error.classList.add("invalid-feedback")
  error.innerHTML = message
  group.appendChild(error)
}

/**
 * @param {Element} element
 * @param {Element} error
 */
export const errorPlacement = (element, error) => {
  let after = null
  const type = element.getAttribute("type")

  if (type === "radio" || type === "checkbox") {
    const checker = element.closest("[class*='checker-']")

    if (checker !== null) {
      checker.parentElement.appendChild(error)
    } else {
      if (type === "radio") {
        const selectGroup = element.closest(".selectgroup")
        const btnGroup = element.closest(".btn-group-toggle")

        if (selectGroup !== null) {
          after = selectGroup
        } else if (btnGroup !== null) {
          after = btnGroup
        }
      } else if (type === "checkbox") {
        const isSwitch = element.closest(".custom-switch")
        after = isSwitch !== null ? isSwitch : element
      }
    }
  } else if (element.parentElement.matches(".input-group")) {
    after = element.parentElement
  } else if (element.classList.contains("select2-hidden-accessible")) {
    after = nextSibling(element, "span")
    after.classList.add("select2-has-error")
  } else {
    after = element
  }

  if (after !== null) {
    after.insertAdjacentElement("afterend", error)
  }
}

/**
 * @param {jQuery} $error
 * @param {jQuery|Array} $element
 */
export const setErrorPlacement = ($error, $element) => {
  const element = $element[0]
  const error = $error[0]
  let after = null
  const type = element.getAttribute("type")

  if (type === "radio" || type === "checkbox") {
    const checker = element.closest("[class*='checker-']")

    if (checker !== null) {
      checker.parentElement.appendChild(error)
    } else {
      if (type === "radio") {
        const selectGroup = element.closest(".selectgroup")
        const btnGroup = element.closest(".btn-group-toggle")

        if (selectGroup !== null) {
          after = selectGroup
        } else if (btnGroup !== null) {
          after = btnGroup
        }
      } else if (type === "checkbox") {
        const isSwitch = element.closest(".custom-switch")
        after = isSwitch !== null ? isSwitch : element
      }
    }
  } else if (element.parentElement.matches(".input-group")) {
    after = element.parentElement
  } else if (element.classList.contains("select2-hidden-accessible")) {
    after = nextSibling(element, "span")
    after.classList.add("select2-has-error")
  } else {
    after = element
  }

  if (after !== null) {
    after.insertAdjacentElement("afterend", error)
  }
}

/**
 * @param {HTMLSelectElement} select
 * @param {boolean} disable
 * @param {boolean} trigger
 */
export const selectReset = (select, disable = false, trigger = true) => {
  const options = select.querySelectorAll("option:not(:first-child)")

  if (options.length > 0) {
    for (const option of options) {
      option.remove()
    }
  }

  if (disable === true) {
    select.disabled = true
  }
  if (trigger === true) {
    triggerEvent(select, "change")
  }
}

/**
 * @param {HTMLSelectElement} select
 * @param {Array<Object>} data
 */
export const selectAddOptions = (select, data) => {
  const options = data.map(o => `<option value="${o.id}">${o.name || o.text}</option>`).join("")
  select.insertAdjacentHTML("beforeend", options)
}

/**
 * @param {HTMLSelectElement} select
 */
export const selectSpinnerShow = (select) => {
  select.classList.add("custom-select-loading")
}

/**
 * @param {HTMLSelectElement} select
 */
export const selectSpinnerHide = (select) => {
  select.classList.remove("custom-select-loading")
}

/**
 * @param {HTMLButtonElement} button
 * @param {string} text
 */
export const startButtonLoading = (button, text = "قيد التنفيذ...") => {
  button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${text}`
}

/**
 * @param {HTMLButtonElement} button
 * @param {string} html
 */
export const stopButtonLoading = (button, html) => {
  button.innerHTML = html
}

export const passwordReveal = () => {
  const passwords = document.querySelectorAll("[data-password]")

  if (passwords.length > 0) {
    passwords.forEach((reveal) => {
      reveal.addEventListener("click", function (event) {
        event.preventDefault()
        const input = siblings(this, "input")
        if (this.dataset.password === "false") {
          this.innerHTML = icon("eye-off-outline")
          input.setAttribute("type", "text")
          this.setAttribute("data-password", "true")
        } else {
          input.setAttribute("type", "password")
          this.setAttribute("data-password", "false")
          this.innerHTML = this.innerHTML = icon("eye-outline")
        }
      })
    })
  }
}

/**
 * @example isImageFile(File, ['jpg', 'png])
 * @param {File} file
 * @param {Array<string>} types
 * @returns {boolean}
 */
export const isImageFile = (file, types) => {
  const pattern = (typeof types !== "undefined" && types.length > 0) ? new RegExp("^image/(" + types.join("|") + ")$") : /^image\/\w+$/

  if (file.type) {
    return pattern.test(file.type)
  }

  return false
}

/**
 * @param {File} file
 * @param {Array<string>} types
 * @returns {boolean}
 */
export const isAllowedDocument = (file, types) => {
  const allowed = {
    "csv": "text/csv",
    "doc": "application/msword",
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "xls": "application/vnd.ms-excel",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "pdf": "application/pdf",
  }
  const mimes = types.map((v) => {
    if (allowed.hasOwnProperty(v)) {
      return allowed[v]
    }
  }).filter((v) => v !== undefined)
  console.log(mimes.join("|"))
  const pattern = new RegExp("^("+mimes.join("|")+")$")

  return pattern.test(file.type)
}

/**
 * @param {File} file
 * @param {Number} max_filesize
 * @returns {boolean}
 */
export const isRegularFilesize = (file, max_filesize) => (max_filesize > file.size)

/**
 * @param {string} selector
 * @returns {boolean}
 */
export const validatePictures = (selector) => {
  const container = document.querySelector(selector)
  const pictures = container.querySelectorAll("input[type=file]:not(.drop-files__fake)")
  let isValid = true

  for (const picture of pictures) {
    const config = JSON.parse(picture.dataset.config.replaceAll("'", '"'))
    const errorField = document.querySelector(`#${picture.getAttribute("id")}-error`)

    if (picture.files.length > 0) {
      const file = picture.files[0]
      if (!isImageFile(file, config.types)) {
        displayElementError(errorField, `يجب عليك اختيار صورة من النوع: ${config.types.join(", ")}.`)
        isValid = false
      } else if (!isRegularFilesize(file, config.size)) {
        displayElementError(errorField, `الرجاء اختيار صورة لا تتجاوز ${formatBytes(config.size)} MB.`)
        isValid = false
      }
    } else if (config.required === true) {
      displayElementError(errorField, "هذه الصورة إلزامية.")
      isValid = false
    }
  }

  return isValid
}

/**
 * @param {string} selector
 * @return {boolean}
 */
export const validateDocuments = (selector) => {
  const container = document.querySelector(selector)
  const documents = container.querySelectorAll("input[type=file]:not(.drop-files__fake)")
  let isValid = true

  if (documents.length > 0) {
    for (const doc of documents) {
      const config = JSON.parse(doc.dataset.config.replaceAll("'", '"'))
      const errorField = document.querySelector(`#${doc.getAttribute("id")}-error`)

      if (doc.files.length > 0) {
        const file = doc.files[0]

        if (!isAllowedDocument(file, config.types)) {
          displayElementError(errorField, `يجب عليك اختيار ملف من النوع: ${config.types.join(", ")}.`)
          isValid = false
        } else if (!isRegularFilesize(file, config.size)) {
          displayElementError(errorField, `الرجاء اختيار ملف لا يتجاوز ${formatBytes(config.size)} MB.`)
          isValid = false
        }
      } else if (config.required === true) {
        displayElementError(errorField, "هذه الوثيقة إلزامية.")
        isValid = false
      }
    }
  }

  return isValid
}

/**
 * @param {Object} data
 * @param {string} container
 * @return {string}
 */
export const formatResponseSelection = (data, container) => {
  if (data.element) {
    $(container).addClass($(data.element).attr("class"))
  }

  return data.name || data.text
}

/**
 * @param {Object} data
 * @param {string} container
 * @return {void|jQuery|HTMLElement|*}
 */
export const formatSelectionRendered = (data, container) => {
  if (!data.id) {
    return formatResponseSelection(data, container)
  }

  return $(`<span class="">${formatResponseSelection(data, container)}</span>`)
}

export const select2WithCreateTags = (element, options) => {
  const defaults = {
    language: "ar",
    dir: "rtl",
    minimumInputLength: 2,
    allowClear: true,
    tags: true,
    multiple: true,
    tokenSeparators: [","],
    ajax: {
      dataType: "json",
      type: "POST",
      delay: 50,
      cache: true,
      data: params => ({q: params.term, page: params.page}),
      processResults: (data, params) => {
        params.page = params.page || 1;

        return {
          results: data.items,
          pagination: {
            more: data.more
          }
        }
      }
    },
    escapeMarkup: (markup) => markup,
    templateResult: formatSelectionRendered,
    templateSelection: formatResponseSelection,
    createTag: params => {
      const term = params.term.trim()

      if (term === "") {
        return null
      }

      return {
        id: term + "|new",
        text: term,
        newTag: true
      }
    }
  }

  $(element).select2(mergeDeep(defaults, options))
  .on("select2:unselect", function (e) {
    if (e.params.originalEvent) {
      e.params.originalEvent.stopPropagation()
    }

    const element = e.params.data.element
    if (typeof element !== "undefined") {
      element.remove()
    }
  })
}

/**
 * @param {HTMLInputElement|HTMLTextAreaElement} element
 * @param {string} to
 * @return {HTMLInputElement|HTMLTextAreaElement}
 */
export const moveCursor = (element, to = 'end') => {
  const position = (to === 'end') ? element.value.length: 0
  element.setSelectionRange(position, position)
  element.focus()

  return element
}

/**
 * @param {string} element
 * @param {Object} options
 * @return {jQuery}
 */
export const select2Multiple = (element, options = {}) => {
  const defaults = {
    language: "ar",
    dir: "rtl",
    multiple: true,
    closeOnSelect: false,
    templateSelection: formatResponseSelection,
    templateResult: data => {
      if (!data.element) {
        return data.name || data.text
      }

      const $element = $(data.element)
      const $wrapper = $("<span></span>")
      $wrapper.addClass($element[0].className)
      $wrapper.text(data.name || data.text)

      return $wrapper
    },
  }

  return $(element).select2(mergeDeep(defaults, options))
  .on("select2:unselect", function (e) {
    if (e.params.originalEvent) {
      e.params.originalEvent.stopPropagation()
    }

    const element = e.params.data.element
    if (typeof element !== "undefined") {
      element.remove()
    }
  })
}

/**
 * @param {
 *   {
 *     uri: string,
 *     modal: HTMLElement,
 *     form: HTMLFormElement,
 *     request: Client,
 *     callback: Function,
 *     id?: string|number,
 *     items?: Array,
 *   }
 * } props
 * @param {PointerEvent} event
 */
export function performAction (props, event) {
  event.preventDefault()
  const modal = props.modal
  const form = props.form
  const request = props.request
  clearFormErrors(form)

  if ($(form).valid()) {
    const button = event.currentTarget
    const buttonHtml = button.innerHTML
    const formData = formToObject(form)
    const uri = props.uri

    if (keyExists(props, "id")) {
      formData.id = props.id.toString()
    }
    if (keyExists(props, "items")) {
      formData.items = props.items
    }

    startButtonLoading(button)
    disableElements([button])
    request.post(uri, formData).then(async response => {
      enableElements([button])
      stopButtonLoading(button, buttonHtml)
      let notify = {}

      if (response.error === false) {
        $(modal).modal("toggle")
        notify.text = response.message
        notify.onClose = () => {
          props.callback()
        }
        await snackbarNotify(notify, response.error)
      } else {
        if (keyExists(response, "validation")) {
          displayModalErrors(modal, response.validation)
        } else if (keyExists(response, "message")) {
          $(modal).modal("toggle")
          notify.text = response.message
          await snackbarNotify(notify, response.error)
        }
      }
    }).catch(() => {
      enableElements([button])
      stopButtonLoading(button, buttonHtml)
    })
  }
}

/**
 * @param {string} selector
 * @param {string} contains
 */
export const moreFilters = (selector, contains) => {
  const more = document.querySelector(selector)

  if (more !== null) {
    const moreText = more.innerText
    more.addEventListener("click", function (event) {
      event.preventDefault()
      if (this.classList.contains(contains)) {
        this.innerText = moreText
      } else {
        this.innerText = "إخفاء"
      }

      this.classList.toggle(contains)
    })
  }
}

/**
 * @param {string} selector
 * @param {HTMLFormElement|string} location
 */
export const clearFilters = (selector, location) => {
  const clear = document.querySelector(selector)

  if (clear !== null) {
    clear.addEventListener("click", function (event) {
      event.preventDefault()
      if (this.classList.contains("text-danger")) {
        if (typeof location === "string") {
          window.location = getBaseUrl(location)
        } else {
          window.location = location.action
        }
      }
    })
  }
}

/**
 * @param {HTMLInputElement} selector
 * @param {Object} params
 * @returns {Promise<RangeSlider>}
 */
export const rangeSlider = async (selector, params = {}) => {
  const {TsRangeSlider} = await import("ts-rangeslider")
  return new TsRangeSlider(selector, mergeDeep({
    grid: false,
    prettifyEnabled: true,
    skin: "round",
    valuesSeparator: " — "
  }, params))
}

/**
 * @param {HTMLSelectElement} cars
 * @param {string} uri
 * @param {Event} event
 * @return {Promise<void>}
 */
export const onBrandChange = async function (cars, uri, event) {
  const value = event.currentTarget.value
  selectReset(cars, true)

  if (value !== '') {
    selectSpinnerShow(cars)
    const { Client } = await import("Http/Client")
    const request = new Client()
    const data = await request.post(uri, {b: value})
    selectAddOptions(cars, data)
    cars.disabled = false
    selectSpinnerHide(cars)
  }
}

/**
 * @param {HTMLInputElement} element
 */
export const priceInput = (element) => {
  element.addEventListener("keydown", (event) => {
    if (!(event.key === " " || event.code === "Space") && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Backspace" && event.keyCode !== 9 && (event.keyCode < 48 || event.keyCode > 57)) {
      event.preventDefault()
      return false
    }
  })
  element.addEventListener("input", function () {
    if (this.value !== "") {
      this.value = formatNumber(this.value)
    }
  })
}

export async function getPosition() {
  const position = document.querySelector("#position")
  const radius = this

  if (position !== null) {
    if (radius.value === "") {
      position.value = ""
      return
    }

    if (position.value === "") {
      if (!navigator.geolocation) {
        await snackbarNotify({
          text: "متصفحك لا يدعم تحديد الموقع الجغرافي.",
          pos: "bottom-center",
          duration: 6_000
        }, true)
      } else {
        const button = document.querySelector(".submit-button")
        button.disabled = true
        selectSpinnerShow(radius)
        navigator.geolocation.getCurrentPosition(function (GeolocationPosition) {
          position.value = `${GeolocationPosition.coords.latitude} ${GeolocationPosition.coords.longitude}`
          selectSpinnerHide(radius)
          button.disabled = false
        }, async function () {
          selectSpinnerHide(radius)
          button.disabled = false
          radius.value = ""
          position.value = ""

          await snackbarNotify({
            text: "يتعذر الحصول على موقعك.",
            pos: "bottom-center",
            duration: 6_000
          }, true)
        }, {
          timeout: 20_000
        })
      }
    }
  }
}
